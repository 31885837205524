import Util from '../util/util.js';
export default class Menu {
  constructor() {
    this.$el = document.getElementById('menu');
    this.$button = document.getElementById('menu-toggle');

    this.toggle = this.toggle.bind(this);
    this.$button.addEventListener('click', this.toggle);
  }
  toggle() {
    this.$el.classList.toggle('show');
    document.documentElement.classList.toggle('menu-open');
  }
}