import Util from '../util/util';
import SmarText from '../components/smart-text.js';
import Menu from '../components/menu.js';

import Lenis from '@studio-freight/lenis'
import gsap from 'gsap';
import * as prismic from 'https://cdn.skypack.dev/@prismicio/client'

export default class Page {
  constructor(options) {
    this.options = options || {};
    this.pageName = this.options.page || 'home';
    window.pageName = this.pageName;

    this.menu = new Menu();
    this.introDone = false;

    this.client = prismic.createClient('eder-small-2023');
    
    this.resize = this.resize.bind(this);
    this.paralax = this.paralax.bind(this);
    this.onDataReady = this.onDataReady.bind(this);
    this.pageReady = this.pageReady.bind(this);

    const initLoad = async () => {
      await this.getData();
      this.onDataReady();
      window.loading.close();
      Util.waitForLoader(() => {
        console.log('loader loaded');
        this.lenis.scrollTo(0, 0);
        this.paralax({velocity: 0});
        this.pageReady();
      });
    }
    initLoad();
  }
  async getData() {
    this.doc = await this.client.getSingle(this.pageName)
    this.menu = await this.client.getSingle('menu')
  }
  onDataReady() {
    const $navLinksContainer = document.querySelector('#nav-links');
    const $menuLinksContainer = document.querySelector('#menu-links');
    const menuItems = [
      /*
      {
        item_title: 'Home',
        item_link: {
          type: 'home'
        }
      },
      */
      ...this.menu.data.menu_items
    ];
    menuItems.forEach(item => {
      const $link = document.createElement('a');
      $link.classList.add('nav-link');
      $link.innerHTML = item.item_title;
      let url = item.item_link.type === 'home' ? `/` : `/${item.item_link.type}.html`;
      $link.setAttribute('href', url);
      console.log(this.pageName, item.item_link.type)
      const isCurrent = this.pageName === item.item_link.type || (this.pageName === 'index' && item.item_link.type === 'home');
      if(isCurrent) {
        $link.classList.add('current');
      }
      $navLinksContainer.appendChild($link);
      $menuLinksContainer.appendChild($link.cloneNode(true));
    });
    const $socialLinksContainer = document.querySelector('.header-social');
    const $menuSocialLinksContainer = document.querySelector('#menu-social');
    this.menu.data.social.forEach(item => {
      const $link = document.createElement('a');
      $link.classList.add('nav-link');
      $link.innerHTML = item.item_title;
      let url = item.item_link.url;
      $link.setAttribute('href', url);
      $link.setAttribute('target', '_blank');
      $link.setAttribute('rel', 'noopener noreferrer');
      if($socialLinksContainer) {
        $socialLinksContainer.appendChild($link);
      }
      $menuSocialLinksContainer.appendChild($link.cloneNode(true));
    });
    this.init();
  }
  init() {
    this.isTouch = Util.isTouch();

    document.documentElement.setAttribute('data-browser', Util.getBrowser());
    document.documentElement.setAttribute('data-touch', this.isTouch);
    this.initLinks();

    window.addEventListener('resize', () => {
      this.resize();
    });
    this.lenis = new Lenis({
      infinite: this.options.infinite || false,
    })
    this.scrollVelocity = {c: 0, t: 0}
    this.scrollVelocityElastic = {c: 0, t: 0, elastic: 0.1}

    this.lenis.on('scroll', (e) => {
      this.paralax(e)
    })
    this.scrollEnded;
    const raf = (time) =>{
      this.lenis.raf(time)
      if(this.lenis.isScrolling) {
        this.scrollEnded = false;
      }
      if(!this.lenis.isScrolling && !this.scrollEnded) {
        this.scrollEnded = true;
        this.onScrollEnd();
      }
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    this.setOS();
  }
  initIntro() {
    const header = document.querySelector('.header');
    if(header) {
      const rows = Array.from(document.querySelectorAll('.header-row')).filter((row, i) => {
        return Util.isInViewportDom(row, 0);
      });
      rows.forEach((row, i) => {
        const hr = row.querySelector('hr');
        gsap.set(hr, {scaleX: 0});

        const letters = Array.from(row.querySelectorAll('.letter'));
        gsap.set(letters, {y: '120%', rotate: -25});

        const description = row.querySelector('.description');
        if(description) {
          const words = Array.from(description.querySelectorAll('strong, .w'));
          gsap.set(words, {opacity: 0});
        }
      });
      const $image = document.querySelector('.header-image');
      if($image) {
        gsap.set($image, {clipPath: 'inset(100% 0 0 0)'});
      }
      const $socialLinksContainer = document.querySelector('.header-social');
      if($socialLinksContainer) {
        const links = Array.from($socialLinksContainer.querySelectorAll('a'));
        gsap.set(links, {opacity: 0});
      }
      const mobileDescription = document.querySelector('#header-mobile-description');
      if(mobileDescription) {
        const words = Array.from(mobileDescription.querySelectorAll('strong, .w'));
        gsap.set(words, {opacity: 0});
      }
      const nextSection = header.nextElementSibling;
      if(nextSection && Util.isInViewportDom(nextSection)) {
        gsap.set(nextSection, {opacity: 0});
      }
    }
    const $nav = document.querySelector('#nav');
    if($nav) {
      gsap.set($nav, {opacity: 0});
    }
  }
  intro() {
    const header = document.querySelector('.header');
    if(header) {
      const rows = Array.from(document.querySelectorAll('.header-row')).filter((row, i) => {
        return Util.isInViewportDom(row, 0);
      });
      rows.forEach((row, i) => {
        const hr = row.querySelector('hr');
        gsap.to(hr, {scaleX: 1, duration: 1.2, ease: 'circ.inOut' });
        
        const letters = Array.from(row.querySelectorAll('.letter'));
        gsap.to(letters, {y: '0%', rotate: 0, duration: 1, stagger: 0.2, delay: 0.5, ease: 'expo.out' });

        const description = row.querySelector('.description');
        if(description) {
          const words = Array.from(description.querySelectorAll('strong, .w'));
          const stagger = words.length > 3 ? 0.06 : 0.15;
          gsap.to(words, {opacity: 1, duration: 2, stagger, delay: 0.9, ease: 'circ.out' });
        }
      });
      const $image = document.querySelector('.header-image');
      if($image) {
        gsap.to($image, {clipPath: 'inset(0% 0 0 0)', duration: 1.6, delay: 1.7, ease: 'expo.out' });
      }
      const mobileDescription = document.querySelector('#header-mobile-description');
      if(mobileDescription) {
        const words = Array.from(mobileDescription.querySelectorAll('strong, .w'));
        const stagger = words.length > 3 ? 0.06 : 0.15;
        gsap.to(words, {opacity: 1, duration: 2, stagger, delay: 0.9, ease: 'circ.out' });
      }
      const nextSection = header.nextElementSibling;
      if(nextSection && Util.isInViewportDom(nextSection)) {
        gsap.to(nextSection, {opacity: 1, duration: 2, delay: 0.9, ease: 'circ.out' });
      }
    }
    const $socialLinksContainer = document.querySelector('.header-social');
    if($socialLinksContainer) {
      const links = Array.from($socialLinksContainer.querySelectorAll('a'));
      gsap.to(links, {opacity: 1, duration: 2, stagger: 0.15, delay: 0.9, ease: 'circ.out' });
    }
    const $nav = document.querySelector('#nav');
    if($nav) {
      gsap.to($nav, {opacity: 1, duration: 2, delay: 0.9, ease: 'circ.out' });
    }
  }
  initLinks() {
    const links = Array.from(document.querySelectorAll('a'));
    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        const href = link.getAttribute('href');
        // is external
        if(href.indexOf('http') === 0) {
          return;
        }
        e.preventDefault();
        this.beforeLeave(() => {
          location.href = href;
        });
      });
    });
  }
  beforeLeave(cb) {
    document.documentElement.classList.add('is-leaving');
    setTimeout(() => {
      if(cb) {
        cb();
      }
    }, 500);
  }
  onScrollEnd() {
    console.log('scroll end')
  }
  elasticEase(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  }
  paralax(data) {
    if(this.introDone) {
      this.scrollVelocity.t = data ? data.velocity : 0
      this.scrollVelocity.t = Math.min(Math.max(this.scrollVelocity.t, -30), 30)
      this.scrollVelocity.c += (this.scrollVelocity.t - this.scrollVelocity.c) * 0.1
      this.scrollVelocityElastic.t = this.elasticEase(Math.abs(this.scrollVelocity.c) / 30) * 30 * (this.scrollVelocity.c < 0 ? -1 : 1)
      this.scrollVelocityElastic.c += (this.scrollVelocityElastic.t - this.scrollVelocityElastic.c) * this.scrollVelocityElastic.elastic
    }

    this.scrollItems = Array.from(document.querySelectorAll(".fade-in-y, .fade-in, .section-title .line, [data-scroll-item]"));
    this.scrollItems.forEach((item) => {
      const offset = item.getAttribute('data-scroll-offset') ? Number(item.getAttribute('data-scroll-offset')) : 0;
      if (!item.classList.contains('show') && Util.isInViewportDom(item, offset)) {
        item.classList.add('show')
      }else if(item.classList.contains('show') && item.getBoundingClientRect().top > this.lvh) {
        item.classList.add('no-transition');
        item.classList.remove('show');
        item.classList.remove('no-transition');
      }
    });
    document.querySelectorAll('.header-row-content').forEach((row, i) => {
      if(Util.isInViewportDom(row, 0)) {
        const rect = row.getBoundingClientRect();
        const p = (rect.top + rect.height * 0.5) / this.lvh;
        let x = (p - 0.5) * 100;
        if (i % 2 === 0) x *= -1;
        row.style.transform = `translateX(${x}px)`;
      }
    })
    document.querySelectorAll('.section-title .line .text').forEach((row, i) => {
      if(Util.isInViewportDom(row.parentElement, 0)) {
        const rect = row.parentElement.getBoundingClientRect();
        const p = (rect.top + rect.height * 0.5) / this.lvh;
        let x = (p - 0.5) * 100;
        if (i % 2 === 0) x *= -1;
        row.style.transform = `translateX(${x}px)`;
      }
    })
  }
  pageReady() {
    this.resize();
    this.initSmartText();
    this.initIntro();
    setTimeout(() => {
      this.intro();
    }, 1000)
  }
  setOS() {
    let os = "osx";
    if (navigator.platform) {
      if (navigator.platform.indexOf("Win") > -1) {
        os = "windows";
      } else if (navigator.platform.indexOf("Mac") > -1) {
        os = "osx";
      } else if (navigator.platform.indexOf("Linux") > -1) {
        os = "linux";
      }
    } else {
      if (navigator.userAgent.indexOf("Windows") > -1) {
        os = "windows";
      } else if (navigator.userAgent.indexOf("Mac") > -1) {
        os = "osx";
      } else if (navigator.userAgent.indexOf("Linux") > -1) {
        os = "linux";
      }
    }
    document.documentElement.setAttribute("data-os", os);
  }
  initSmartText() {
    const smartTexts = Array.from(document.querySelectorAll('.smart-text'));
    smartTexts.forEach((item) => {
      new SmarText({$el: item});
    });
  }
  resize() {
    this.isTouch = Util.isTouch();
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
  
    // Set the viewport size for css
    const vh = this.winH * 0.01;
    this.isMobile = window.innerWidth <= 1024;
  
    if (this.isMobile && !this.mobileInitialized) {
      this.mobileInitialized = true;
      this.lvh = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    } else if (!this.isMobile) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.lvh = window.innerHeight;
    }
    const setContainer = (name) => {
      const container = document.createElement('div');
      container.classList.add(name);
      document.body.append(container);
      const rect = container.getBoundingClientRect();
      container.remove();
      document.documentElement.style.setProperty(
        `--${name}-margin`,
        `${rect.left}px`
      );
      document.documentElement.style.setProperty(
        `--${name}-width`,
        `${rect.width}px`
      );
    }
    setContainer('container');
    setContainer('small-container');
  }
}